define(['app','siteObj'], (app, siteObj) => {
  const vetPractice = () => {

    const component = {};

    const _config = {
      maxLength: 2,
      textInput: '[data-js-element="account-signup-vet-practice"]',
      hiddenInput: '[data-js-element="account-signup-vet-practice-value"]',
      resultBox: '[id="vetPracticeResultBox"]',
      resultList: '[id="vetPracticeResultsList"]',
      resultListItem: '[class="vetPracticeResultListItem"]',
      vetMessageInput: '[id="vetPracticeResults"]',
      body: 'body'
    }

    const _state = {
      messageInputData: {
        isValid: false,
        message: ''
      }
    };

    const _init = (element) => {
      component.element = element;
      component.elements = {
        textInput: element.querySelector(component.config.textInput),
        hiddenInput: element.querySelector(component.config.hiddenInput),
        resultBox: element.querySelector(component.config.resultBox),
        resultList: element.querySelector(component.config.resultList),
        vetMessageInput: element.querySelector(component.config.vetMessageInput),
        body: document.getElementsByTagName(component.config.body)[0]
      };
      component.bind();
    };

    const _bind = () => {
      component.elements.textInput && component.elements.textInput.addEventListener('input', (e) => {
        component.regExValidation(e);
        component.validateInput();
      });
      component.elements.body && component.elements.body.addEventListener('click', () => component.outsideClickBody());
    };

    const _regExValidation = (e) => {
      if (e.target.value.match(/[^a-zA-Z0-9äöüÄÖÜß ]/g)) {
        e.target.value = e.target.value.replace(/[^a-zA-Z0-9äöüÄÖÜß ]/g, '');
      }
    }

    const _validateInput = () => {
      const siteCode = siteObj.siteCode;

      let vetMessageInput = component.elements.textInput.value;
      let vetMessageInputLength = component.elements.textInput.value.length;

      if (vetMessageInputLength > component.config.maxLength ) {
        app.ajax.get({
          url: `/${siteCode}/accountCreationForm/dynamicOptionLookup.listjson?formFieldName=vetPractice&searchWord=${vetMessageInput}`,
          success: component.validationSuccess,
          error: component.validationError
        });
      }
      else {
        component.elements.resultList.style.display = 'none';
      }

      component.elements.hiddenInput.value = vetMessageInput || '';
    };

    const _validationSuccess = (result) => {
      component.state.messageInputData.isValid = 'true';
      component.renderSuggestions(result);
    };

    const _renderSuggestions = (data) => {
      // Parse the data
      let results = JSON.parse(data);

      // Empty the suggestion list
      component.elements.resultList.innerHTML = '';

      if (results.length === 0) {
        component.elements.resultList.style.display = 'none';
      }
      else {

        for (let item of results) {
          let newItem = component.renderSuggestionsData();
          newItem.innerHTML = item.displayName;
          component.resultItemClick(newItem, item);
          component.elements.resultList && component.elements.resultList.appendChild(newItem);
        }
        component.elements.resultList.style.display = 'block';
      }
    };

    const _renderSuggestionsData = () =>  {
      let newItem = document.createElement('li');
      newItem.classList.add('vetPracticeResultListItem');
      newItem.setAttribute('aria-selected','true');
      newItem.setAttribute('role','tab');
      newItem.setAttribute('tabindex','0');
      return newItem;
    }

    const _resultItemClick = (newItem, item) => {
      let addingText = component.elements.textInput;
      let hiddenInput = component.elements.hiddenInput;
      newItem.addEventListener('click',() => {
        addingText.value = item.displayName;
        hiddenInput.value = item.value;
        component.elements.resultList.style.display = 'none';
      } )
    };

    const _outsideClickBody = () => {
      component.elements.resultList.style.display = 'none';
    };

    component.init = _init;
    component.bind = _bind;
    component.validateInput = _validateInput;
    component.validationSuccess = _validationSuccess;
    component.renderSuggestions = _renderSuggestions;
    component.resultItemClick = _resultItemClick;
    component.regExValidation = _regExValidation;
    component.renderSuggestionsData = _renderSuggestionsData;
    component.outsideClickBody = _outsideClickBody;
    component.config = _config;
    component.state = _state;

    return component;
  };
  return vetPractice;
});

